import React from "react";

import Layout from "./Layout";

export default ({ children, pageContext, ...props }) => {
  if (pageContext.prismicPreviewPage) {
    return children;
  }

  if (pageContext.customSEO) {
    return (
      <Layout customSEO {...props}>
        {children}
      </Layout>
    );
  }

  return <Layout {...props}>{children}</Layout>;
};
