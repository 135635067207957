module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: "Momentium - Design | Business | Technology", // Navigation and Site Title
  titleAlt: "Momentium Consulting", // Title for JSONLD
  description:
    "Momentium is a student implementation organisation that provides a diverse range of services for start-ups and SME's in Melbourne.",
  headline: "", // Headline for schema.org JSONLD
  url: "https://momentium.com.au", // Domain of your site. No trailing slash!
  siteLanguage: "en", // Language Tag on <html> element
  logo: "/logos/logo-1024.png", // Used for SEO
  ogLanguage: "en_US", // Facebook Language

  // JSONLD / Manifest
  favicon: "src/favicon.png", // Used for manifest favicon generation
  shortName: "Momentium", // shortname for manifest. MUST be shorter than 12 characters
  author: "Momentium", // Author for schemaORGJSONLD
  themeColor: "#3D63AE",
  backgroundColor: "#EBEDF2",

  twitter: "", // Twitter Username
  facebook: "momentiumconsulting", // Facebook Site Name
  googleAnalyticsID: "UA-108813316-3",

  skipNavId: "reach-skip-nav", // ID for the "Skip to content" a11y feature
};
