/* eslint no-unused-expressions: 0 */
/* eslint react/destructuring-assignment: 0 */

import "@reach/skip-nav/styles.css";
import "styles/theme.scss";
import "typeface-muli";
import "typeface-montserrat";

import { fonts, reset, theme } from "@/styles";
import { Global, css } from "@emotion/core";
import { ThemeProvider } from "emotion-theming";
import React, { useEffect, useRef, useState } from "react";
import UIkit from "uikit";

import SEO from "../SEO";
import Aside from "./Aside";
import Footer from "./Footer";
import Header from "./Header";
import Loader from "./Loader";
import SkipNavLink from "./SkipNavLink";

const globalStyle = css`
  ${reset}

  main {
    position: relative;
  }

  @font-face {
    font-family: "Leixo";
    font-style: normal;
    font-weight: normal;
    src: local("Leixo"), url(${fonts.LeixoTTF}) format("truetype");
  }

  @font-face {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: bold;
    src: local("Helvetica Neue"),
      local("Helvetica-Neue") url(${fonts.HelveticaNeueBoldTTF})
        format("truetype");
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ::selection {
    color: ${theme.colors.bg};
    background-color: ${theme.colors.primary};
  }

  @media (max-width: ${theme.breakpoints.m}) {
    html {
      font-size: 16px !important;
    }
  }
  @media (max-width: ${theme.breakpoints.s}) {
    h1 {
      font-size: 2.369rem !important;
    }
    h2 {
      font-size: 1.777rem !important;
    }
    h3 {
      font-size: 1.333rem !important;
    }
    h4 {
      font-size: 1rem !important;
    }
    h5 {
      font-size: 0.75rem !important;
    }
    h6 {
      font-size: 0.563rem !important;
    }
  }
`;

const PureLayout = ({ children, customSEO, path }) => {
  const offcanvas = useRef();
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  useEffect(() => {
    offcanvas.current = UIkit.offcanvas("#offcanvas", {
      overlay: true
    });

    UIkit.util.on("#offcanvas", "show", () => setShowOffcanvas(true));
    UIkit.util.on("#offcanvas", "hide", () => setShowOffcanvas(false));

    return () => offcanvas.current && offcanvas.current.$destroy();
  }, []);

  const handleToggle = () => {
    if (!offcanvas.current) return;
    offcanvas.current.toggle();
  };

  return (
    <ThemeProvider theme={theme}>
      {/* <Loader key={path} /> */}
      <Global styles={globalStyle} />
      <SkipNavLink />
      {!customSEO && <SEO />}
      <Header
        path={path}
        showOffcanvas={showOffcanvas}
        toggleOffcanvas={handleToggle}
      />
      <Aside
        path={path}
        onNavigate={() => {
          if (offcanvas.current) {
            offcanvas.current.hide();
          }
        }}
      />
      <main id="reach-skip-nav">{children}</main>
      <Footer />
    </ThemeProvider>
  );
};

const Layout = props => <PureLayout {...props}>{props.children}</PureLayout>;

export default Layout;
