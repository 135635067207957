import website from "config/website";
import React from "react";

const SkipNavLink = ({ children, ...props }) => (
  <a {...props} href={`#${website.skipNavId}`} data-reach-skip-link>
    {children}
  </a>
);

export default SkipNavLink;

SkipNavLink.defaultProps = {
  children: "Skip to content"
};
