import { theme } from "@/styles";
import { ClassNames } from "@emotion/core";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import React from "react";

const BaseButton = styled(Link)`
  background-color: ${({ color }) => theme.colors[color] || color};
`;

const AnchorTag = styled.a`
  background-color: ${({ color }) => theme.colors[color] || color};
`;

const Button = ({ children, className, ...props }) => (
  <ClassNames>
    {({ cx }) => (
      <BaseButton
        className={cx(
          "uk-button uk-button-small uk-link-reset uk-border-rounded",
          className
        )}
        {...props}
      >
        {children}
      </BaseButton>
    )}
  </ClassNames>
);

export const CallToAction = ({ children, className, ...props }) => (
  <ClassNames>
    {({ cx }) => (
      <Button className={cx("uk-margin-large-top", className)} {...props}>
        {children}
      </Button>
    )}
  </ClassNames>
);

export const ExternalLink = ({ children, className, ...props }) => (
  <ClassNames>
    {({ cx }) => (
      <AnchorTag
        className={cx(
          "uk-margin-large-top uk-button uk-button-small uk-link-reset uk-border-rounded",
          className
        )}
        {...props}
      >
        {children}
      </AnchorTag>
    )}
  </ClassNames>
);

export const ScrollTo = ({ children, className, ...props }) => (
  <ClassNames>
    {({ cx, css }) => (
      <a
        className={cx(
          "uk-display-block uk-text-center uk-position-bottom",
          className,
          css`
            bottom: 64px;
          `
        )}
        data-uk-scroll
        uk-icon="icon: arrow-down; ratio: 3"
        {...props}
      >
        {children}
      </a>
    )}
  </ClassNames>
);

export default Button;

Button.BaseButton = BaseButton;
Button.ExternalLink = ExternalLink;
Button.CTA = CallToAction;
Button.ScrollTo = ScrollTo;
