import { theme } from "@/styles";
import { Link, StaticQuery, graphql } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import React from "react";

import Button from "../Button";

const NavItem = ({ to, children, ...props }) => (
  <li className="uk-navbar-item">
    <Link to={to} {...props}>
      {children}
    </Link>
  </li>
);

const Header = ({ showOffcanvas, toggleOffcanvas, path }) => {
  return (
    <header>
      <nav
        className="uk-navbar-container uk-navbar-transparent uk-grid"
        data-uk-navbar
      >
        <div className="uk-navbar-left">
          <Link className="uk-navbar-item uk-logo" to="/">
            MOMENTIUM
          </Link>
        </div>
        <div className="uk-navbar-right">
          <button
            onClick={toggleOffcanvas}
            type="button"
            className="uk-navbar-toggle uk-hidden@m jonos"
            data-uk-icon={
              showOffcanvas
                ? "icon: close; ratio: 1.5"
                : "icon: menu; ratio: 1.5"
            }
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              data-svg="menu"
            >
              <rect x="2" y="4" width="16" height="1"></rect>
              <rect x="2" y="9" width="16" height="1"></rect>
              <rect x="2" y="14" width="16" height="1"></rect>
            </svg>
          </button>

          <div className="uk-navbar-nav uk-visible@m">
            <NavItem
              activeStyle={{ color: theme.colors.blue }}
              to="/who-we-are/"
            >
              About Us
            </NavItem>

            <NavItem
              activeStyle={{ color: theme.colors.pink }}
              to="/what-we-do/"
            >
              Our Services
            </NavItem>

            <NavItem
              activeStyle={{ color: theme.colors.green }}
              to="/portfolios/"
              partiallyActive
            >
              Portfolio
            </NavItem>

            <NavItem
              activeStyle={{ color: theme.colors.green }}
              to="/blogs/"
              partiallyActive
            >
              Blogs
            </NavItem>

            {/* <NavItem
              activeStyle={{ color: theme.colors.yellow }}
              to="/for-students/"
            >
              For Students
            </NavItem> */}
          </div>

          {path.startsWith("/for-students") || path.startsWith("/join-us") ? (
            <div className="uk-navbar-item uk-visible@m">
              <OutboundLink
                className="uk-button uk-button-small uk-link-reset uk-border-rounded"
                style={{ background: "#FF714E" }}
                href="https://demo.momentium.com.au/"
              >
                Sign Up
              </OutboundLink>
            </div>
          ) : (
            <div className="uk-navbar-item uk-visible@m">
              <Button color="orange" to="/contact-us/">
                Let's talk
              </Button>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
};

export default (props) => (
  <StaticQuery
    query={`${query}`}
    render={(data) => <Header data={data} {...props} />}
  />
);

const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
