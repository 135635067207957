module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"momentium-website","accessToken":"MC5YTE1WekJRQUFBQ20wZHdx.EykO77-977-977-9cu-_vSMFD--_vWMC77-9RXZAZu-_ve-_ve-_ve-_ve-_ve-_vS7vv73vv73vv73vv73vv73vv70","defaultLang":"en-au","path":"/preview","previews":true,"pages":[{"type":"Project","match":"/portfolios/:uid","path":"/portfolios/","component":"/opt/build/repo/src/templates/project.js"},{"type":"Blog_post","match":"/blogs/:uid","path":"/blogs/","component":"/opt/build/repo/src/templates/blog-post.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"config/typography.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-108813316-3","exclude":["/preview/**"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Momentium - Design | Business | Technology","short_name":"Momentium Consulting","description":"Momentium is a student implementation organisation that provides a diverse range of services for start-ups and SME's in Melbourne.","start_url":"","background_color":"#EBEDF2","theme_color":"#3D63AE","display":"standalone","icon":"src/favicon.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
