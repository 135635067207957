// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-blogs-js": () => import("./../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-portfolios-js": () => import("./../src/pages/portfolios.js" /* webpackChunkName: "component---src-pages-portfolios-js" */),
  "component---src-templates-project-js": () => import("./../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-us-js": () => import("./../src/pages/join-us.js" /* webpackChunkName: "component---src-pages-join-us-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-what-we-do-js": () => import("./../src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-pages-who-we-are-js": () => import("./../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */)
}

