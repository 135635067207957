import styled from "@emotion/styled";
import gyroscope from "images/loadershort.gif";
import React from "react";

const Container = styled.div({
  zIndex: 10,
  position: "fixed",
  height: "100%",
  width: "100%",
  backgroundColor: "#102131",
});

const ContentContainer = styled.div({
  position: "relative",
  width: "80px",
  margin: "42vh auto",
});

const SpinnerImg = styled.img({
  width: "100%",
  opacity: "0",
});

const Loader = () => {
  return (
    <Container className="loadercontainer">
      <ContentContainer>
        <SpinnerImg src={gyroscope} alt="gyroscope" />
      </ContentContainer>
    </Container>
  );
};

export default Loader;
