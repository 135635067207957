const theme = {
  colors: {
    primary: "#ffffff",
    bg: "#102131",
    black: "#000",
    greyLight: "#EBEDF2",
    greyBlue: "#a2bce2",
    grey: "#595C62",
    greyDark: "#303643",
    greyDarker: "#1c252b",
    orange: "#FF714E",
    pink: "#FF5974",
    red: "#B72D24",
    blue: "#00C7D0",
    green: "#17E17C",
    greenDark: "#0CAF83",
    yellow: "#F6D51F",
    purple: "#6266d6"
  },
  maxWidth: "1000px",
  maxWidthText: "720px",
  breakpoints: {
    xs: "400px",
    s: "600px",
    m: "900px",
    l: "1200px"
  }
};

export default theme;
