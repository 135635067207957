import "./src/styles/global.css";

import { registerLinkResolver } from "gatsby-source-prismic-graphql";
import React from "react";

import Layout from "./src/components/Layout";
import { linkResolver } from "./src/prismic-configuration";

registerLinkResolver(linkResolver);

export const onClientEntry = () => {
  const UIkit = require("uikit");
  const Icons = require("uikit/dist/js/uikit-icons");
  // loads the Icon plugin
  UIkit.use(Icons);
};

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>;
};
