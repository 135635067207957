import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import React from "react";

import Button from "../Button";

const Aside = ({ onNavigate, path }) => (
  <aside id="offcanvas">
    <nav className="uk-offcanvas-bar uk-flex uk-flex-column">
      <ul className="uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical">
        <Link
          onClick={onNavigate}
          className="uk-navbar-item aside-bar"
          to="/who-we-are"
        >
          About Us
        </Link>
        <Link
          onClick={onNavigate}
          className="uk-navbar-item aside-bar"
          to="/what-we-do"
        >
          Our Services
        </Link>
        <Link
          onClick={onNavigate}
          className="uk-navbar-item aside-bar"
          to="/portfolios"
        >
          Portfolio
        </Link>
        <Link
          onClick={onNavigate}
          className="uk-navbar-item aside-bar"
          to="/for-students"
        >
          For Students
        </Link>

        {path.startsWith("/for-students") || path.startsWith("/join-us") ? (
          <OutboundLink
            href="https://demo.momentium.com.au/"
            style={{
              marginTop: "16px",
              display: "block",
              backgroundColor: "#FF714E",
              borderRadius: "30px",
              color: "white",
              padding: "8px",
              paddingLeft: "15px",
              paddingRight: "15px",
              border: "none",
            }}
          >
            Sign Up
          </OutboundLink>
        ) : (
          <Button
            to="/contact-us"
            style={{
              display: "block",
              backgroundColor: "#FF714E",
              borderRadius: "30px",
              color: "white",
              padding: "8px",
              paddingLeft: "15px",
              paddingRight: "15px",
              border: "none",
            }}
          >
            Let's talk
          </Button>
        )}
      </ul>
    </nav>
  </aside>
);

export default Aside;
